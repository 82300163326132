<script>
    import axios from "axios";
    import { mapActions, mapState } from "vuex";
    import ThePersonalNavigation from "@/ThePersonal/ThePersonalNavigation";
    import { metaInfo } from "@/core/MetaInfo";

    export default {
        name: "ThePersonal",
        components: { ThePersonalNavigation },
        data() {
            return {
                isNoAccess: false,
            };
        },
        computed: {
            ...mapState("Profile", ["user"]),
        },
        mounted() {
            axios.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    if (error.response.status === 403) {
                        this.callAuthorization();
                    }
                }
            );
            if (!this.user?.id) {
                this.getProfileUser().then((user) => {
                    if (!user?.id) {
                        this.callAuthorization();
                    }
                });
            }
        },
        methods: {
            ...mapActions("Profile", ["getProfileUser"]),
            goToDiscountsPage() {
                this.$router.push({ name: "ThePersonalDiscounts" });
            },
            callAuthorization() {
                this.isNoAccess = true;
                this.$popup.show("modal-profile-auth");
            },
        },
        metaInfo() {
            return metaInfo.getMeta("personal");
        },
    };
</script>

<template>
    <div class="the-personal" :class="{ 'the-personal--no-access': isNoAccess }">
        <div class="the-personal__inner">
            <aside class="the-personal__side js-scroll-nav">
                <div class="the-personal__navigation js-scroll-nav-wrapper">
                    <the-personal-navigation></the-personal-navigation>
                </div>
            </aside>
            <main class="the-personal__main">
                <router-view></router-view>
            </main>
        </div>
        <div class="js-divider-stop"></div>
    </div>
</template>

<style lang="scss">
    .the-personal {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 450px;
        padding: 60px 0 70px;
        @include bp($bp-desktop-sm) {
            padding: 30px 0 90px;
        }

        &.the-personal--no-access {
            filter: blur(5px);
            pointer-events: none;
        }
    }

    .the-personal__inner {
        flex-grow: 1;
        width: 100%;
        max-width: 1292px;
        margin: 0 auto;
        padding: 0 15px 32px;
        @include bp($bp-desktop-sm) {
            display: flex;
            align-items: flex-start;
            padding: 0 16px;
        }
    }

    .the-personal__navigation {
        @media screen and (max-width: 991px) {
            &.fix-scroll-block {
                z-index: 12;
                left: 0;
                width: 100% !important;
            }
        }
    }

    .the-personal__main {
        width: 100%;
        @include bp($bp-desktop-sm) {
            min-height: 450px;
        }
    }

    .the-personal__side {
        @include bp($bp-desktop-sm) {
            display: block;
            width: 100%;
            max-width: 250px;
            margin-right: 40px;
        }
    }

    .the-personal-skeleton {
        position: relative;
        overflow: hidden;
        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
            );
            animation: shimmer 2s infinite;
            content: "";
            opacity: 0.6;
        }
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
</style>
