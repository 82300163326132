<script>
    import { mapActions } from "vuex";
    import { isMobile, stickyScroll, throttle } from "@/utils";
    import ThePersonalIcon from "@/ThePersonal/components/ThePersonalIcon";

    export default {
        name: "ThePersonalNavigation",
        components: { ThePersonalIcon },
        data() {
            return {
                stickyScrollWithThrottle: throttle(this.stickyNavScroll, 25),
                isOpenedMobileMenu: false,
                menuCurrentTitle: "Личный кабинет",
                headerDesktop: null,
                headerMobile: null,
                headerDesktopHeight: 0,
                headerMobileHeight: 0,
                navScrollPosition: 0,
            };
        },
        computed: {
            stickyOffset() {
                return this.$root.$_nameDev ? 13 : 0;
            },
            routes() {
                return [
                    { title: "Главная", name: "ThePersonalMain", icon: "main" },
                    {
                        title: "Скидки и акции",
                        name: "ThePersonalDiscounts",
                        icon: "discounts",
                    },
                    { title: "Мои данные", name: "ThePersonalData", icon: "data" },
                    { title: "Мои заказы", name: "ThePersonalOrders", icon: "orders" },
                    { title: "Мои адреса", name: "ThePersonalAddresses", icon: "addresses" },
                    { title: "Кешбэк", name: "ThePersonalBonuses", icon: "bonuses" },
                    { title: "Помощь", name: "ThePersonalSupport", icon: "support" },
                ];
            },
        },
        watch: {
            $route(to) {
                const title = this.findMenuTitle(to.name);
                this.setMenuTitle(title);
            },
        },
        mounted() {
            window.addEventListener("scroll", this.stickyScrollWithThrottle);

            const title = this.findMenuTitle(this.$route.name);
            this.setMenuTitle(title);

            this.headerDesktop = document.querySelector(".js-desktop-header");
            this.headerMobile = document.querySelector(".js-mobile-header");

            this.$nextTick(() => {
                this.headerDesktopHeight = this.headerDesktop.getBoundingClientRect().height;
                this.headerMobileHeight = this.headerMobile.getBoundingClientRect().height;
                this.navScrollPosition = this.headerDesktopHeight + 30;
            });
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.stickyScrollWithThrottle);
        },
        methods: {
            ...mapActions("Profile", ["logoutProfile"]),
            stickyNavScroll() {
                const el = document.querySelector(".js-scroll-nav");
                const elWrap = document.querySelector(".js-scroll-nav-wrapper");

                if (el && elWrap) {
                    if (isMobile(992)) {
                        stickyScroll({
                            el,
                            elWrap,
                            topMargin: this.headerMobileHeight,
                            calcWidth: false,
                            startPoint: -(this.headerMobileHeight + this.stickyOffset),
                            classNameDivider: "js-footer-stop-divider",
                        });
                    } else {
                        stickyScroll({
                            el,
                            elWrap,
                            topMargin: this.navScrollPosition,
                        });
                    }
                }
            },
            setMenuTitle(title) {
                title === "Главная"
                    ? (this.menuCurrentTitle = "Личный кабинет")
                    : (this.menuCurrentTitle = title);
                this.isOpenedMobileMenu = false;
            },
            findMenuTitle(name) {
                return this.routes.find((route) => name === route.name).title;
            },
            logoutClickHandler() {
                this.logoutProfile().then(() => {
                    if (this.$root.$_nameDev) {
                        this.$router.push({ name: "Home" });
                    } else {
                        window.location.href = "/";
                    }
                });
            },
        },
    };
</script>

<template>
    <nav class="the-personal-navigation">
        <div
            class="the-personal-navigation__title"
            :class="{ opened: isOpenedMobileMenu }"
            @click="isOpenedMobileMenu = !isOpenedMobileMenu"
        >
            {{ menuCurrentTitle }}
            <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.16 4.32a.78.78 0 011.1-.16L6 7l3.74-2.84a.78.78 0 011.1.16.8.8 0 01-.16 1.12L6 9 1.32 5.44a.8.8 0 01-.16-1.12z"
                    fill="#40404B"
                />
            </svg>
        </div>
        <ul class="the-personal-navigation__list" :class="{ opened: isOpenedMobileMenu }">
            <li
                class="the-personal-navigation__item"
                v-for="route in routes"
                :key="route.name"
            >
                <the-personal-icon
                    v-if="route.icon !== 'main'"
                    name="arrow"
                ></the-personal-icon>
                <router-link
                    class="the-personal-navigation__link"
                    :class="{
                        'the-personal-navigation__link--active': $route.name === route.name,
                    }"
                    :to="{ name: route.name }"
                >
                    <img
                        v-if="route.icon === 'discounts'"
                        src="@/ThePersonal/assets/icons/discounts.svg"
                        alt=""
                    />
                    <the-personal-icon v-else :name="route.icon"></the-personal-icon>
                    <span>{{ route.title }}</span>
                </router-link>
            </li>
            <li class="the-personal-navigation__item">
                <button class="the-personal-navigation__link" @click="logoutClickHandler">
                    <the-personal-icon name="exit"></the-personal-icon>
                    <span>Выйти</span>
                </button>
            </li>
        </ul>
    </nav>
</template>

<style lang="scss">
    .the-personal-navigation {
        position: absolute;
        z-index: 12;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        border: $personal-common-border-full;
        transition: opacity 0.2s ease-in;
        @include bp($bp-desktop-sm) {
            position: static;
            padding: 8px 9px;
            border-radius: $personal-box-radius;
            box-shadow: $personal-common-shadow-max;
        }
    }

    .the-personal-navigation__title {
        position: relative;
        z-index: 20;
        padding: 12px 20px 15px;
        color: $personal-title-color;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        background-color: #fff;
        box-shadow: 0 0 30px #f2f2f5;
        transition: box-shadow 0.2s ease-in;
        svg {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%) rotate(-90deg);
            transition: transform 0.2s ease-in;
        }
        &.opened {
            box-shadow: none;
            svg {
                transform: translateY(-50%) rotate(0);
            }
        }
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .the-personal-navigation__list {
        position: absolute;
        left: 0;
        right: 0;
        background-color: #fff;
        overflow: hidden;
        box-shadow: 0 0 30px #d1d3e3;
        transform: translateY(-150%);
        transition: transform 0.3s ease-in;
        &.opened {
            transform: translateY(0);
        }
        @include bp($bp-desktop-sm) {
            position: static;
            box-shadow: none;
            border-radius: $personal-box-radius;
            transform: translateY(0);
        }
    }

    .the-personal-navigation__item {
        position: relative;
        svg.icon-arrow {
            position: absolute;
            top: 50%;
            right: 20px;
            width: 12px;
            height: 12px;
            fill: $personal-active-color;
            transform: translateY(-50%);
        }
        &:last-child {
            .the-personal-navigation__link {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 10px;
                    left: 10px;
                    height: 1px;
                    background: $personal-divider-gradient;
                }
            }
        }

        @include bp($bp-desktop-sm) {
            svg.icon-arrow {
                display: none;
            }
        }
    }

    .the-personal-navigation__link {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px 20px;
        color: $personal-nav-color;
        font-size: 16px;
        font-weight: $personal-nav-weight;
        line-height: 20px;
        transition: background-color 0.2s ease-in, color 0.2s ease-in;
        -webkit-tap-highlight-color: transparent;
        svg,
        img {
            width: 16px;
            height: 16px;
            margin-right: 10px;
            fill: $personal-nav-color;
            stroke: $personal-nav-color;
            transition: fill 0.2s ease-in, stroke 0.2s ease-in;
        }
        @include bp($bp-desktop-sm) {
            padding: 12px;
            border-radius: $personal-box-radius;
            &:focus-visible {
                @include outline(-2px);
            }
            &:hover {
                background-color: $personal-nav-fill;
            }
        }
    }

    .the-personal-navigation__link--active {
        color: $personal-nav-color--active;
        background-color: $personal-nav-fill;
        svg {
            fill: $personal-nav-color--active;
            stroke: $personal-nav-color--active;
        }
    }
</style>
